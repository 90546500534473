import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MAIN_CONTAINER } from 'src/app/constants/common.constants';
import { getGoToTab } from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-go-to-container',
  templateUrl: './go-to-container.component.html',
  styleUrls: ['./go-to-container.component.scss'],
})
export class GoToContainerComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _auth: AuthService,
    private _deviceService: DeviceService,
    private _store: StorageService
  ) {}

  ngOnInit() {
    this._route.queryParams.subscribe((param) => {
      const path = param['path'];
      if (path) {
        console.log('storing path in localforage.');
        this.savePath(path);
      }
    });
  }

  async savePath(path) {
    await this._store.storePath(path).catch((e) => console.log(e));
    console.log('path segments stored');
    if (this._auth.getJWT()) {
      const tab = getGoToTab(path);
      TabService.getInstance().openTab(tab);
    } else {
      console.log('No auth token, need to reload.');
      const mainTab = TabService.getInstance().buildNewTab(
        MAIN_CONTAINER,
        false,
        'Home',
        null,
        'main'
      );
      if (this._deviceService.isMobile()) {
        TabService.getInstance().setMobileTab(mainTab);
      } else {
        TabService.getInstance().openTab(mainTab);
      }
    }
  }
}
