<p-dialog [(visible)]="displayDialog" showEffect="fade" [modal]="true" [showHeader]="false" [transitionOptions]="'0ms'"
  [style]="{width: '50%', height: '70%'}">
  <div>
    <div class="header">{{'COMMON.LABEL.PRIVACY_POLICY' | translate}}:</div>
    <p-scrollPanel [style]="{width: '100%', height: '55vh'}">
      <div class="dialog-body">
        TransCanada is committed to protecting your privacy and the Personal Information you provide us. The purpose of this
        Notice is to inform you about the practices that govern the processing of Personal Information collected, used, or disclosed
        for CR LINC (the "System").
        <br><br>
        The System supports management of Control Room pipeline operations to meet regulatory requirements. Personal Information
        involved in the System includes, but is not limited to, your name, contact information, Controller notes, and Shift turnover 
        information (if applicable to you). Use of the System aligns the terms of use as outlined by 
        <a href="https://www.tcenergy.com/privacy/">TC Energy's Privacy Statment</a>. 
        The System is a TC Energy cloud-based solution located in Ohio and Oregon, United States. Continued use of this System hereafter 
        is your continued acceptance of TC Energy's Privacy Statement. 
        <br><br>
        Additionally, as you use CR LINC, you may come across personal information about identifiable individuals. In accordance with 
        <a href="https://transcanada.sharepoint.com/sites/1TC-CorpCompliance/SitePages/Policies.aspx">TC Energy's Protection of Personal Information Policy</a>,
        please do not use, disclose, retain, or otherwise disseminate any such personal information for any reason other than the legitimate business purpose 
        for which you were granted access to CR LINC. If you have any questions or concerns, please contact the 
        <a href="mailto: privacy_office@tcenergy.com">Privacy Office</a>.
        <br><br>
        By using this SYSTEM, I acknowledge that I have read and understood the TC Energy Privacy Statement and agree to the terms and conditions identified in 
        the Privacy STATEMENT.
      </div>
    </p-scrollPanel>
    <div class="button-right-align">
      <button pButton label="{{'COMMON.LABEL.BUTTONS.I_CONSENT' | translate}}" (click)="policyAccepted()"></button>
    </div>
  </div>
</p-dialog>
