import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  HttpClientXsrfModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BREAKPOINT, FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GCMCCommonModule } from './common/common.module';
import { ContactsModule } from './contacts/contacts.module';
import { CoreModule } from './core/core.module';
import { AuthGuard } from './guards/auth.guard';
import { CanDeactivateGuard } from './guards/deactivate.guard';
import { RouteGuard } from './guards/route.guard';
import { LocationsModule } from './locations/locations.module';
import { MonlogsModule } from './monlogs/monlogs.module';
import { HttpEnvInterceptor } from './services/http.env.interceptor';
import { ServicesModule } from './services/services.module';
import { CrmModule } from './crm/crm.module';
import { MapsModule } from './maps/maps.module';
import { MessageModule } from 'primeng/message';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CardModule } from 'primeng/card';
import { BlockUIModule } from 'primeng/blockui';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ToastModule } from 'primeng/toast';
import { LoginComponent } from './login/login/login.component';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { FullCalendarModule } from '@fullcalendar/angular';
import { OperationalMonitoringModule } from './operational-monitoring/operational-monitoring.module';
import { ReportingModule } from './reporting/reporting.module';

const oktaAuth = new OktaAuth({
  issuer: environment.oktaAuthIssuerURL,
  clientId: environment.oktaClientId,
  redirectUri: window.location.origin + '/authorization-code/callback',
  responseType: ['id_token'],
  pkce: true,
  scopes: ['email', 'profile', 'openid', 'crlinc'],
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: 300, // Refreshes auth token 300 seconds (5 minutes) before it expires
  },
});

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

/* "Barrel" of Http Interceptors */
/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpEnvInterceptor, multi: true },
];

const CUSTOM_BREAKPOINTS = [
  {
    alias: 'xs',
    suffix: 'xs',
    mediaQuery: '(max-width: 30em)',
    overlapping: false,
  },
  {
    alias: 'sm',
    suffix: 'sm',
    mediaQuery: '(min-width: 30.0625em) and (max-width: 40em)',
    overlapping: false,
  },
  {
    alias: 'md',
    suffix: 'md',
    mediaQuery: '(min-width: 40.0625em) and (max-width: 64em)',
    overlapping: false,
  },
  {
    alias: 'lg',
    suffix: 'lg',
    mediaQuery: '(min-width: 64.0625em) and (max-width: 90em)',
    overlapping: false,
  },
  {
    alias: 'xl',
    suffix: 'xl',
    mediaQuery: '(min-width: 90.0625em)',
    overlapping: false,
  },
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: CUSTOM_BREAKPOINTS,
  multi: true,
};

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientXsrfModule,
    BrowserAnimationsModule,
    GCMCCommonModule,
    CoreModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    DialogModule,
    BreadcrumbModule,
    ServicesModule,
    SidebarModule,
    DropdownModule,
    AutoCompleteModule,
    CardModule,
    FormsModule,
    AppRoutingModule,
    FlexLayoutModule,
    ToastModule,
    ConfirmDialogModule,
    BlockUIModule,
    MonlogsModule,
    OperationalMonitoringModule,
    ContactsModule,
    LocationsModule,
    AdminModule,
    CrmModule,
    MapsModule,
    ReportingModule,
    OktaAuthModule,
    FullCalendarModule,
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    AuthGuard,
    RouteGuard,
    CustomBreakPointsProvider,
    MessageService,
    ConfirmationService,
    CanDeactivateGuard,
    httpInterceptorProviders,
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
