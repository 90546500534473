import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { ToolbarComponent } from './components/toolbar.component';
import { GoToContainerComponent } from './containers/go-to-container.component';
import { LogoutContainerComponent } from './containers/logout-container.component';
import { MainContainerComponent } from './containers/main-container.component';
import { PrivacyConsentContainerComponent } from './containers/privacy-consent-container.component';
import { CoreModule } from '../core/core.module';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CardModule } from 'primeng/card';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CheckboxModule } from 'primeng/checkbox';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToolbarModule } from 'primeng/toolbar';
import { PasswordModule } from 'primeng/password';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    ButtonModule,
    PasswordModule,
    RouterModule,
    DropdownModule,
    ToolbarModule,
    DialogModule,
    TableModule,
    PanelMenuModule,
    MessagesModule,
    MessageModule,
    AutoCompleteModule,
    CardModule,
    CoreModule,
    ScrollPanelModule,
    CheckboxModule,
    TabMenuModule,
    TranslateModule.forChild({}),
  ],
  declarations: [
    ToolbarComponent,
    MainContainerComponent,
    LogoutContainerComponent,
    PrivacyConsentContainerComponent,
    GoToContainerComponent,
  ],
  exports: [
    ToolbarComponent,
    MainContainerComponent,
    LogoutContainerComponent,
    PrivacyConsentContainerComponent,
  ],
})
export class GCMCCommonModule {}
