import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DeviceSize } from 'src/app/core/enums/deviceSize.enum';
import { AuthenticatedComponent } from './authenticated.component';
import { ThemeService } from 'src/app/services/theme.service';
import { SelectItem } from 'primeng/api';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { AppTheme } from 'src/app/services/theme.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent extends AuthenticatedComponent implements OnInit {
  @Input() title;
  @Input() sessionExpire = -1;
  @Input() networkStatus = 'online';
  @Input() show = true;
  @Output() menuToggle = new EventEmitter<any>();
  @Output() logoutEvent = new EventEmitter<any>();
  @Input() name;

  canChangeTheme: boolean = false;
  themeOptions: SelectItem[] = [
    {
      label: 'Light',
      value: 'saga-green',
      disabled: true,
    },
    {
      label: 'Dark',
      value: 'arya-green',
      disabled: false,
    },
  ];
  theme: AppTheme = this.themeOptions[0].value;

  constructor(
    private _themeService: ThemeService,
    private _authApi: AuthApiService
  ) {
    super();
  }

  ngOnInit() {
    this._authApi.userClaimsUpdated.asObservable().subscribe((_) => {
      this.canChangeTheme =
        this._authApi.doesUserHaveClaim('ChangeCRLINCTheme');
    });

    this._themeService.themeChanged
      .asObservable()
      .subscribe((selectedTheme) => {
        this.theme = selectedTheme;
        this.themeOptions.forEach((x) => (x.disabled = false));
        this.themeOptions.find((x) => x.value === this.theme).disabled = true;
      });
  }

  isLargeDevice(): boolean {
    if (!this.deviceSize) return true; // Err on the side of caution
    return this.ifDeviceMatches([DeviceSize.LG, DeviceSize.XL]);
  }

  changeTheme() {
    this.themeOptions.forEach((x) => (x.disabled = false));
    this.themeOptions.find((x) => x.value === this.theme).disabled = true;
    this._themeService.switchTheme(this.theme);
  }
}
