import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TabService } from 'src/app/core/services/tab.service';
import { MAIN_CONTAINER } from 'src/app/constants/common.constants';
import { DeviceService } from 'src/app/services/device.service';


@Component({
  selector: 'app-logout-container',
  templateUrl: './logout-container.component.html',
  styleUrls: ['./logout-container.component.scss']
})
export class LogoutContainerComponent implements OnInit {

  constructor(private _router: Router, private _deviceService: DeviceService) { }

  ngOnInit() {
    // Cognito automatically logged in on all routes. This preserves that functionality
    this._router.navigate(['/main']);
  }

  logBackIn() {
    const mainTab = TabService.getInstance().buildNewTab(MAIN_CONTAINER, false, 'Home', null, 'main');
    if (this._deviceService.isMobile()) {
      TabService.getInstance().setMobileTab(mainTab);
    } else {
      TabService.getInstance().openTab(mainTab);
    }
  }
}
