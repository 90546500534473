// angular
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MenuBuilderService } from '../services/menu-builder.service';
import { Observable, of } from 'rxjs';

/**
 * Route guard used to check to see if the user is authenticated
 * on route change.  If not the user is redirected to the
 * login page.
 */
@Injectable()
export class RouteGuard  {

    constructor(private _router: Router,
                private _auth: AuthService,
                private _menuBuilder: MenuBuilderService) {}

    /**
     * Method to determine if the user is authenticated and can get to the route
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      // TODO: Log message of unauthorized.
      return of(this._menuBuilder.hasMenuItem(state.url));
    }
}
