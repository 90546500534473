import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { EnvironmentService } from './environment.service';
import { LoadingService } from './loading.service';

/**
 * Intercepts all HTTP traffic to prepend the environment URL as well
 * as required HTTP headers.
 */
@Injectable()
export class HttpEnvInterceptor implements HttpInterceptor {
  constructor(private _env: EnvironmentService, private _loader: LoadingService) { }

  attemptingRefreshCount = 0;

  checkStatus(key, check, event, status) {
    if (key === check) {
      if (event.body.errors && event.body.errors.length >= 1) {
        this._loader.setLoadingStatus(status, false);
      } else {
        this._loader.setLoadingStatus(status, true);
      }
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    if (!req.url.includes('assets/environment.json')) {

    }

    // The graphql refresh token will get into a loop if a 401 is called.
    // need to break out by redirecting back to the auth url.
    if (req.url.includes('graphql') && req.body.operationName === 'refreshToken') {
      this.attemptingRefreshCount++;
    }

    return next.handle(req).pipe(map(event => {

      // if (this.attemptingRefreshCount > 2 && navigator && navigator.onLine) {
      if (this.attemptingRefreshCount > 2) {
        this._env.checkIfOnline().pipe(take(1)).subscribe(() => {
          console.error('Refresh attempts failed, refreshing application');
          window.location.href = this._env.getAuthUrl();
        }, (error) => {
          console.error('Failed to verify if application is online.');
        });
      }

      if (event instanceof HttpResponse) {
        if (event.body && event.body.data) {
          if (event.body.data['refreshToken']) {
            this.attemptingRefreshCount = 0;
          } else {
            Object.keys(event.body.data).forEach((key) => {
              this.checkStatus(key, 'getContacts', event, 'contacts');
              this.checkStatus(key, 'getContactsDelta', event, 'contacts');
              this.checkStatus(key, 'getContactLists', event, 'contactLists');
              this.checkStatus(key, 'getContactListsDelta', event, 'contactLists');
              this.checkStatus(key, 'getFacilities', event, 'facilities');
              this.checkStatus(key, 'getFacilitiesDelta', event, 'facilities');
              this.checkStatus(key, 'getMonitoringLogs', event, 'monLogs');
              this.checkStatus(key, 'getMonitoringLogsDelta', event, 'monLogs');
              this.checkStatus(key, 'getLocations', event, 'locations');
              this.checkStatus(key, 'getLocationsDelta', event, 'locations');
              this.checkStatus(key, 'getEmailGroups', event, 'emailGroups');
              this.checkStatus(key, 'getEmailGroupsDelta', event, 'emailGroups');
              this.checkStatus(key, 'getExternalContactLists', event, 'externalContactLists');
              this.checkStatus(key, 'getExternalContactListsDelta', event, 'externalContactLists');
              // add more status checks here for loading screen.
            });
          }
        }
      }
      return event;
    }));
  }
}
