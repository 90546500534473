import { Injectable } from '@angular/core';

/**
 * Eventually this will be used to keep track of user profile settings.
 */
export class UserProfileService {

  private name: string;

  constructor() { }

  setProfile(name, cacheFlag) {
    this.name = name;
  }
}
