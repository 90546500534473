import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-privacy-consent-container',
  templateUrl: './privacy-consent-container.component.html',
  styleUrls: ['./privacy-consent-container.component.scss']
})
export class PrivacyConsentContainerComponent implements OnInit {

  @Input() displayDialog = false;

  @Output() policyAcceptedEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  policyAccepted(): void {
    this.displayDialog = false;
    this.policyAcceptedEvent.emit();
  }
}
