import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { IMG_LOC } from 'src/app/constants/common.constants';
import { TabService } from 'src/app/core/services/tab.service';
import { Contact } from 'src/app/model/contacts/contact';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { LoadingService } from 'src/app/services/loading.service';
import { StorageService } from 'src/app/services/storage.service';
import { getGoToTab } from 'src/app/core/functions/common-functions';
import { DeviceService } from 'src/app/services/device.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
})
export class MainContainerComponent implements OnInit {
  isCacheEnabled: boolean = false;
  filteredContacts: Contact[];
  showOfflineDialog = false;
  form: UntypedFormGroup;
  tcEnergyImg: string = IMG_LOC + 'tc logo transparent.png';
  loadingGoTo: boolean = false;

  constructor(
    private _breadCrumbService: BreadCrumbBuilderService,
    private _loadingService: LoadingService,
    private _store: StorageService,
    private _confirm: ConfirmationService,
    private _deviceService: DeviceService,
    private _translateService: TranslateService,
    private _fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      contact: [null, Validators.required],
    });

    this._breadCrumbService.clearBreadCrumbs();

    this.navigateToSavedPath();
  }

  isMobile(): boolean {
    return this._deviceService.isMobile();
  }

  async navigateToSavedPath() {
    const path = await this._store.getPath().catch((e) => console.log(e));
    if (path) {
      this.loadingGoTo = true;
      const clone = String(path);
      if (clone) {
        await this._store.storePath(null);
        const tab = getGoToTab(clone);
        TabService.getInstance().openTab(tab);
        this.loadingGoTo = false;
      }
    } else {
      this.loadingGoTo = false;
    }
  }

  isAppLoaded(): boolean {
    return this._loadingService.isLoaded();
  }

  closeAllTabs(): void {
    const unsaved$ = this._translateService.get(
      'COMMON.MESSAGES.CONFIRMATION.CLOSE_ALL_TAB_MGS'
    );
    const yes$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
    const no$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
    const header$ = this._translateService.get(
      'COMMON.MESSAGES.HEADERS.CLOSE_TABS'
    );
    forkJoin([unsaved$, yes$, no$, header$]).subscribe((messages) => {
      this._confirm.confirm({
        header: messages[3],
        icon: 'fa fa-question-circle',
        acceptVisible: true,
        rejectVisible: true,
        acceptLabel: messages[1],
        rejectLabel: messages[2],
        message: messages[0],
        accept: () => {
          TabService.getInstance().closeTab(null, true);
        },
        reject: () => {},
      });
    });
  }
}
