import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainContainerComponent } from './common/containers/main-container.component';
import { LogoutContainerComponent } from './common/containers/logout-container.component';
import { LoginComponent } from './login/login/login.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { GoToContainerComponent } from './common/containers/go-to-container.component';

/**
 * Route definitions.
 */
const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'authorization-code/callback', component: OktaCallbackComponent },
  {
    path: 'goto',
    component: GoToContainerComponent,
    canActivate: [OktaAuthGuard],
  },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutContainerComponent },
  {
    path: 'main',
    component: MainContainerComponent,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'locations',
    loadChildren: () =>
      import('./locations/locations.module').then((m) => m.LocationsModule),
  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('./contacts/contacts.module').then((m) => m.ContactsModule),
  },
  {
    path: 'monLogs',
    loadChildren: () =>
      import('./monlogs/monlogs.module').then((m) => m.MonlogsModule),
  },
  {
    path: 'operational-monitoring',
    loadChildren: () =>
      import('./operational-monitoring/operational-monitoring.module').then(
        (m) => m.OperationalMonitoringModule
      ),
  },
  {
    path: 'crm',
    loadChildren: () => import('./crm/crm.module').then((m) => m.CrmModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'maps',
    loadChildren: () => import('./maps/maps.module').then((m) => m.MapsModule),
  },
  {
    path: 'reporting',
    loadChildren: () => import('./reporting/reporting.module').then((m) => m.ReportingModule),
  },
  /**
   * The following handles bad routes.  This is where we would put a 404 page not found component if we want to support that.
   * Right now all we do is redirect to /main so we don't allow users to guess valid pages.
   *
   * NOTE: This must stay as the last route so others trigger route events before falling to the redirect.
   */
  { path: '**', redirectTo: '/main' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
