import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadCrumbBuilderService } from './breadcrumb-builder.service';
import { DeviceService } from './device.service';
import { EnvironmentService } from './environment.service';
import { LogAndMessageService } from './log-and-message.service';
import { AppsyncService } from './appsync.service';
import { LocationApiService } from './location-api.service';
import { FacilityApiService } from './facility-api.service';
import { ContactListApiService } from './contact-list-api.service';
import { ContactListBuilderService } from './contact-list-builder.service';
import { ContactApiService } from './contact-api.service';
import { ContactBuilderService } from './contact-builder.service';
import { UserProfileService } from './user-profile.service';
import { HttpLinkModule } from 'apollo-angular-link-http';

@NgModule({
  imports: [CommonModule],
  providers: [
    DeviceService,
    BreadCrumbBuilderService,
    EnvironmentService,
    AppsyncService,
    LocationApiService,
    FacilityApiService,
    ContactListApiService,
    ContactApiService,
    ContactBuilderService,
    ContactListBuilderService,
    UserProfileService,
    LogAndMessageService,
    {
      // need to load the environment service first so we can get at the API URL.
      provide: APP_INITIALIZER,
      useFactory: (envSettings: EnvironmentService) => () =>
        envSettings.getSettings(),
      deps: [EnvironmentService],
      multi: true,
    },
  ],
})
export class ServicesModule {}
