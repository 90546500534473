<div class="button-bar" *ngIf="isAppLoaded()">
  <div class="landing-page">
    <div class="image">
      <a>
        <img [src]="tcEnergyImg" />
      </a>
    </div>
  </div>
</div>

<app-progress-spinner [enabled]="loadingGoTo"></app-progress-spinner>

<div class="buttons">
  <div *ngIf="!isMobile()" class="auto-width">
    <button
      pButton
      class="action-item"
      (click)="closeAllTabs()"
      label="Close All Tabs"
      *ngIf="isAppLoaded()"
    ></button>
  </div>
</div>

<div *ngIf="isAppLoaded()">
  <div class="text">
    Welcome to CR LINC. This is the application used by Gas Control to support
    pipeline operations. CR LINC is where you'll maintain your contact
    information, availability, and contact lists for the facilities and
    pipelines you're responsible for.
  </div>
  <div class="text">
    Below is a link to the CR LINC SharePoint resource page. Here, you'll find
    helpful training videos and QRG's that will assist you with using the CR
    LINC application.
  </div>
  <div class="text">
    Any issues with CR LINC should be reported to the ServiceNow team by calling
    888-546-3484 or by email at
    <A HREF="mailto:is_service_desk@tcenergy.com"
      >is_service_desk&#64;tcenergy.com</A
    >
  </div>

  <div class="red-underline text">
    The Monitoring Center can no longer make field employees unavailable or
    rotate contact lists. Please follow the QRG's on the SharePoint site to
    complete these tasks. If you encounter issues, please contact ServiceNow.
  </div>

  <div class="text">
    CR LINC SharePoint:
    <a href="https://transcanada.sharepoint.com/sites/cr-linc" target="_blank"
      >https://transcanada.sharepoint.com/sites/cr-linc</a
    >
  </div>
</div>
