import { Component, Input } from '@angular/core';
import { DeviceComponent } from '../../core/components/device.component';

/**
 * Base class to indicate if a component is authenticated and can be shown.
 */
@Component({
  template: '',
})
export class AuthenticatedComponent extends DeviceComponent {
  @Input() show: boolean;
}
